<template>
  <div style="min-height: 400px;">
    <p v-if="!this.receiptStatus">
      {{ $t('hat.broadcast.detailTip') }}
    </p>
    <a-table
      :columns="columns"
      style="height: 450px;"
      :scroll="{ y: 300 }"
      :pagination="false"
      :loading="loading"
      :dataSource="tableList"
    >
    </a-table>
  </div>
</template>
<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import { LayoutContent } from '@triascloud/x-blocks';
import { getDetailList } from '@/services/smart-hat/broadcast';

@Component({
  components: {
    LayoutContent,
  },
})
export default class BroadcastDetail extends Vue {
  @Prop({ type: String, default: '' }) id;
  @Prop({ type: Boolean, default: true }) receiptStatus;

  mounted() {
    this.getTableList();
  }
  loading = false;
  tableList = [];
  async getTableList() {
    this.loading = true;
    try {
      const data = {
        input: this.id,
        size: 500,
        current: 1,
      };
      this.tableList = (await getDetailList(data)).records;
      this.loading = false;
    } catch {
      this.loading = false;
      return false;
    }
  }
  get columns() {
    let column = [
      {
        title: this.$t('hat.broadcast.sendDevice'),
        dataIndex: 'deviceName',
        ellipsis: true,
        width: 250,
        customRender: (text, record) =>
          record.deviceUserName
            ? `${text}(${record.deviceUserName})`
            : `${text}`,
      },
      {
        title: this.$t('hat.broadcast.user'),
        dataIndex: 'deviceUserName',
      },
      {
        title: this.$t('hat.broadcast.playbackStatus'),
        dataIndex: 'broadcastReceiveStatus',
        customRender: text => this.playStatus[text],
      },
      {
        title: this.receiptStatus
          ? this.$t('hat.broadcast.listeningTime')
          : this.$t('hat.broadcast.estimatedListening'),
        dataIndex: this.receiptStatus ? 'listeningTime' : 'estimatedTime',
        customRender: text =>
          text ? this.$moment(text).format('YYYY-MM-DD HH:mm:ss') : '',
      },
    ];
    if (!this.receiptStatus) {
      delete column[2];
    }
    return column;
  }
  playStatus = {
    PLAYED: '已读',
    NOT_PLAYED: '未读',
    NOT_RECEIVED: '未读',
  };
}
</script>
<style lang="less" module></style>
