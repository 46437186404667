<template>
  <div :class="$style.manageBox">
    <layout-content>
      <template v-slot:header-left>
        <span :class="$style.title">{{ $path('/hat/broadcast') }}</span>
      </template>
      <div :class="$style.manageContent" ref="content" slot="content">
        <flex-col-content>
          <div :class="$style.form">
            <a-form-model layout="inline">
              <a-form-model-item>
                <a-input
                  style="width: 200px"
                  v-model="searchParams.input"
                  @input.stop="() => handleSearch()"
                  :placeholder="$t('common.action.search')"
                >
                  <a-icon slot="prefix" type="search" />
                </a-input>
              </a-form-model-item>
              <a-form-model-item>
                <a-select
                  style="width: 200px"
                  v-model="searchParams.isPerson"
                  :options="selectOption"
                  @change="() => handleSearch()"
                />
              </a-form-model-item>
              <a-form-model-item style="margin-left: auto;">
                <a-button
                  v-if="$p.action('CREATE')"
                  type="primary"
                  icon="plus"
                  size="small"
                  @click="handleAdd()"
                  >{{ $t('electricBoxMock.button.add') }}</a-button
                >
              </a-form-model-item>

              <a-form-model-item style="margin-right: 0;">
                <a-button
                  v-if="$p.action('DELETE')"
                  type="primary"
                  icon="delete"
                  size="small"
                  @click="handleDeleteAll()"
                  >{{ $t('enterpriseManagement.camera.delete.txt') }}</a-button
                >
              </a-form-model-item>
            </a-form-model>
          </div>

          <template #footer>
            <PaginationTable
              ref="tableRef"
              showSelection
              :columns="columns"
              :api="api"
              :slotKeys="['action']"
              rowKey="pkId"
              :searchParams="searchParams"
            >
              <template #action="{record}">
                <span :class="$style.buttonGroup" @click.stop>
                  <a
                    :class="$style.button"
                    v-if="
                      record.broadcastStatus === 'DRAFT' && $p.action('UPDATE')
                    "
                    @click.stop="handleEdit(record)"
                    >{{ $t('hat.deviceManagement.edit') }}</a
                  >
                  <a
                    :class="$style.button"
                    v-else
                    @click.stop="handleDetail(record)"
                    >{{ $t('hat.clockRecord.detailed') }}</a
                  >
                  <a
                    v-if="$p.action('DELETE')"
                    :class="$style.button"
                    @click.stop="handleDelete(record)"
                    >{{ $t('enterpriseManagement.camera.delete.txt') }}</a
                  >
                  <a
                    v-if="$p.action('UPDATE')"
                    @click.stop="handleCopy(record)"
                    >{{ $t('hat.electronFence.copy') }}</a
                  >
                </span>
              </template>
            </PaginationTable>
          </template>
        </flex-col-content>
      </div>
    </layout-content>
  </div>
</template>
<script>
import { Component, Vue } from 'vue-property-decorator';
import { LayoutContent } from '@triascloud/x-blocks';
import { createFormModal, createModal } from '@triascloud/x-components';
import Detail from './components/detail.vue';
import Tip from '@/components/tip';
import BroadcastForm from './components/broadcast-form.vue';
import {
  getBroadcastList,
  deleteBroadcast,
  deleteBroadcastByIds,
} from '@/services/smart-hat/broadcast';
import { delay } from '@triascloud/utils';
import { Debounce } from 'lodash-decorators';
import FlexColContent from '@/views/hat/components/flex-col-content';
import PaginationTable from '@/views/hat/components/pagination-table';

@Component({
  components: {
    LayoutContent,
    Detail,
    BroadcastForm,
    FlexColContent,
    PaginationTable,
  },
})
export default class Broadcast extends Vue {
  api(data) {
    return getBroadcastList({
      ...data,
      isPerson: !!data.isPerson,
    });
  }

  selectOption = [
    {
      value: 0,
      label: this.$t('iotScreenManage.all'),
    },
    {
      value: 1,
      label: this.$t('common.my'),
    },
  ];
  get columns() {
    return [
      {
        align: 'left',
        title: this.$t('hat.broadcast.theme'),
        width: 200,
        dataIndex: 'theme',
        ellipsis: true,
      },
      {
        align: 'left',
        title: this.$t('hat.broadcast.content'),
        width: 300,
        ellipsis: true,
        dataIndex: 'content',
      },
      {
        title: this.$t('hat.broadcast.status'),
        dataIndex: 'broadcastStatus',
        customRender: text =>
          text === 'DRAFT'
            ? this.$t('hat.broadcast.draft')
            : this.$t('hat.broadcast.send'),
        width: 100,
        ellipsis: true,
      },
      {
        title: this.$t('hat.broadcast.broadcastTime'),
        dataIndex: 'broadcastTime',
        width: 200,
        ellipsis: true,
        customRender: text => this.$moment(text).format('YYYY-MM-DD HH:mm:ss'),
      },
      {
        title: this.$t('hat.broadcast.deviceNumber'),
        dataIndex: 'counts',
        ellipsis: true,
        width: 150,
        customRender: text => text + this.$t('hat.broadcast.device'),
      },
      {
        title: this.$t('electricBoxMock.columns.operator'),
        dataIndex: 'editName',
        ellipsis: true,
        width: 100,
      },
      {
        title: this.$t('hat.electronFence.editTime'),
        dataIndex: 'lastEditTime',
        width: 200,
        ellipsis: true,
        customRender: text => this.$moment(text).format('YYYY-MM-DD HH:mm:ss'),
      },
      {
        title: this.$t('electricBoxMock.columnsInfo.operation'),
        key: 'action',
        fixed: 'right',
        scopedSlots: { customRender: 'action' },
      },
    ];
  }
  searchParams = {
    input: '',
    isPerson: 0,
  };
  async getTableList() {
    this.loading = true;
    const data = {
      current: this.pagination.current,
      size: this.pagination.pageSize,
      input: this.input,
    };
    try {
      const { records, total } = await getBroadcastList(data);
      this.pagination.total = total;
      this.tableList = records;
      this.loading = false;
    } catch {
      this.loading = false;
      return false;
    }
  }
  @Debounce(500)
  handleSearch() {
    this.$refs.tableRef.search();
  }
  handleDetail(record) {
    createModal(
      () => <Detail id={record.pkId} receiptStatus={record.receiptStatus} />,
      {
        width: 800,
        title: this.$t('hat.broadcast.broadcastDetail'),
      },
    );
  }
  checked = false;
  async handleDelete(record) {
    const text = `${this.$t('hat.broadcast.form.deleteBroadcast')}：${
      record.theme
    }？`;
    const tips = this.$t('hat.broadcast.form.deleteTip');
    try {
      await createFormModal(
        () =>
          record.broadcastStatus === 'DRAFT' ? (
            <Tip iconStyle={{ padding: '0 0 22px' }}>
              <template slot="txt">
                <span>{text}</span>
              </template>
            </Tip>
          ) : (
            <Tip iconStyle={{ padding: '0 0 22px' }}>
              <template slot="txt">
                <div>{text}</div>
                <ARadio checked={this.checked} onClick={() => this.onChange()}>
                  {this.$t('hat.broadcast.form.radioTip')}
                </ARadio>
              </template>
              <span slot="subTxt">{tips}</span>
            </Tip>
          ),
        {
          width: '442px',
          title: this.$t('common.tips.prompt'),
          onOk: async () => {
            try {
              await deleteBroadcast(record.pkId, this.checked);
              this.$message.success(this.$t('camera.removeSuccessCode'));
              this.$refs.tableRef.refresh();
            } catch {
              return false;
            }
          },
        },
      );
    } catch {
      return false;
    }
  }
  onChange() {
    this.checked = !this.checked;
  }
  async handleAdd(record) {
    this.showFormModel(record, 'add');
  }
  handleEdit(record) {
    this.showFormModel(record, 'edit');
  }
  handleCopy(record) {
    this.showFormModel(record, 'copy');
  }

  async handleDeleteAll() {
    if (this.$refs.tableRef.selectedKeys.length <= 0)
      return this.$message.error(this.$t('hat.broadcast.deleteToast'));

    await createFormModal(
      () => (
        <Tip iconStyle={{ padding: '0 0 22px' }}>
          <template slot="txt">
            <div>
              {this.$t('hat.broadcast.deleteMultiterm', {
                value: this.$refs.tableRef.selectedKeys.length,
              })}
            </div>
            <ARadio checked={this.checked} onClick={() => this.onChange()}>
              {this.$t('hat.broadcast.form.radioTip')}
            </ARadio>
          </template>
          <span slot="subTxt">{this.$t('hat.broadcast.form.deleteTip')}</span>
        </Tip>
      ),
      {
        width: '442px',
        title: this.$t('common.tips.prompt'),
        onOk: async () => {
          try {
            await deleteBroadcastByIds(
              this.$refs.tableRef.selectedKeys,
              this.checked,
            );
            this.$message.success(
              this.$t('hat.deviceManagement.delete.deleted'),
            );
            this.$refs.tableRef.refresh();
          } catch (err) {
            return false;
          }
        },
      },
    );
  }

  submitType = 'submit';
  async showFormModel(record, operationType) {
    try {
      const result = await createFormModal(
        () => (
          <BroadcastForm
            editData={record}
            operationType={operationType}
            submitType={this.submitType}
          />
        ),
        {
          width: 860,
          title: this.$t('hat.broadcast.form.editBroadcast'),
          maskClosable: false,
          footer: (h, submit, cancel) => [
            <AButton
              ghost
              onClick={() => {
                cancel();
              }}
            >
              {this.$t('msg.cancel')}
            </AButton>,
            <AButton
              type="primary"
              ghost
              onClick={async () => {
                this.submitType = 'copy';
                await delay();
                submit();
              }}
            >
              {this.$t('hat.broadcast.form.saveDraft')}
            </AButton>,
            <AButton
              type="primary"
              onClick={async () => {
                this.submitType = 'confirm';
                await delay();
                submit();
              }}
            >
              {this.$t('msg.confirm')}
            </AButton>,
          ],
        },
      );
      if (result) {
        this.$refs.tableRef.refresh();
      }
    } catch {
      return false;
    }
  }
}
</script>
<style lang="less" module>
.manageBox {
  flex: 1;
  display: flex;
  overflow: hidden;
  .title {
    font-size: 16px;
  }
  .manageContent {
    padding: 20px 28px;
    min-width: 850px;
    // height: 100%;
    // .table {
    //   height: 94%;
    //   padding-bottom: 10px;
    // }
    :global {
      .ant-table-wrapper .ant-spin-container .ant-table-body,
      .ant-table-wrapper .ant-table-scroll .ant-table-body {
        flex: 1;
        overflow: auto !important;
        max-height: none !important;
      }
    }
  }
  .form {
    width: 100%;
    padding-bottom: 15px;
    :global(.ant-form) {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
  .buttonGroup {
    display: flex;
    width: max-content;
    gap: 20px;
  }
}
</style>
