<template>
  <a-select
    show-search
    allowClear
    v-model="form.projectId"
    :placeholder="$t('electricBoxMock.placeholder.projectId')"
    v-bind="$attrs"
    option-filter-prop="children"
    @change="handleChange"
    :filter-option="false"
    @search="v => selectSearch(v)"
  >
    <a-select-option
      :value="item.id"
      v-for="item in projectList"
      :key="item.id"
    >
      {{ item.value }}
    </a-select-option>
  </a-select>
</template>
<script>
import { Component, Vue, Model, Prop } from 'vue-property-decorator';
import { getProjectList } from '@/services/smart-hat/device-management';
import { Debounce } from 'lodash-decorators';

@Component()
export default class ProjectSelect extends Vue {
  @Model('bindProjectId', { type: String }) projectId;
  @Prop({ type: Boolean, default: false }) hasOwner; // 用于提高查询性能
  @Prop({ type: Array, default: () => [] }) defaultItems; // 初始默认项

  mounted() {
    this.getProjectList();
  }
  form = {
    projectId: undefined,
  };
  handleChange(v) {
    this.$emit('bindProjectId', v);
    this.$emit('change');
  }
  projectList = [];
  async getProjectList(input = undefined) {
    this.form.projectId = this.projectId;
    const { records } = await getProjectList({
      current: 1,
      size: 200,
      input,
      hasOwner: this.hasOwner,
    });
    this.projectList = records.map(item => ({
      id: item.pkId,
      value: item.pmName,
    }));
    if (this.defaultItems.length) {
      this.projectList.unshift(...this.defaultItems);
    }
  }
  @Debounce(300)
  selectSearch(keyword) {
    this.getProjectList(keyword);
  }
}
</script>
