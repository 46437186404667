import request from '../request';

const serviceName = '/iot-smart-helmet/broadcast';

export function getBroadcastList(data) {
  return request(`${serviceName}/page`, {
    method: 'GET',
    body: data,
  });
}

export function getDetailList(data) {
  return request(`${serviceName}/detailsPage`, {
    method: 'GET',
    body: data,
  });
}

export function addBroadcast(data) {
  return request(`${serviceName}`, {
    method: 'POST',
    body: data,
  });
}

export function putBroadcast(data) {
  return request(`${serviceName}`, {
    method: 'PUT',
    body: data,
  });
}

export function deleteBroadcast(id, checked = false) {
  return request(`${serviceName}/${id}?method=${checked}`, {
    method: 'DELETE',
  });
}

export function deleteBroadcastByIds(ids, checked = false) {
  return request(`${serviceName}/batch`, {
    body: {
      broadcastIds: ids,
      method: checked,
    },
    method: 'DELETE',
  });
}
